import { render, staticRenderFns } from "./index.vue?vue&type=template&id=15a53338"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=15a53338&prod&lang=css"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ExternalLink: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/ExternalLink.vue').default,ImgShieldsIoBadge: require('/usr/home/wds/cloned/pawel-wilczek.mojezapiski.pl/components/ImgShieldsIoBadge.vue').default})
